import styled from "styled-components";

export const Screen = styled.div`
  font-family: 'Lora', serif;
  font-color: #fff;
  background-color: #fff;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const NavBar = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 48px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
`;

export const Logo = styled.p`
  font-size: 18px;
  padding-top: 16px;
  padding-left: 16px;
  width: 71px;
  height: 37px;
  text-align: left;
  opacity: 1;
`;

export const NavMenu = styled.div`
  padding-top: 18px;
  font-size:12px;
  display: flex;
  alignItems: center;
  margin-right: 20px;  
`;

export const StyledButton = styled.button`
  font-family: 'Lora', serif;
  padding: 10px;
  border-radius: 30px;
  border: none;
  background-color: var(--secondary);
  font-weight: 600;
  color: var(--secondary-text);
  width: 256px;
  cursor: pointer;
  box-shadow: 0px 6px 2px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 5px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 2px 6px 0px -2px rgba(250, 250, 250, 0.3);
  transition: .1s;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  :hover{
    transform: translate3d(0, 4px, 0);
    box-shadow: none;
  }
  }
`;

export const StyledButtonClamed = styled.button`
  font-family: 'Lora', serif;
  padding: 10px;
  border-radius: 30px;
  border: none;
  background-color: rgb(120, 120, 120);
  font-weight: 600;
  color: var(--secondary-text);
  width: 256px;
  cursor: pointer;
  }
`;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

export const TextTitle = styled.p`
  text-align: center;
  color: #4d4d4d;
  font-size: 24px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0.48px;
  opacity: 1;
`;

export const TextDescription = styled.p`
  color: #fff;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  opacity: 1;
  text-align: center;
  font-weight: 500;
`;


export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

export const VideoBackground = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;  /* 動画をコンテンツの下に配置するために追加 */
`;
