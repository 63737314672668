import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


function App() {
  const dispatch = useDispatch();

  const [hold, setHold] = useState(0);
  const [ZukanID, setZukanID] = useState(0);

  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [totalSupply, setTotalSupply] = useState(0);
  const [claimed, setClaimed] = useState(0);
  const [saleStart, setSaleStart] = useState(false);
  const [proof, setProof] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [TransferNft, setTransferNft] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [FreemintNum, setMintNum] = useState("");
  const [tokenOfOwnerByIndex, setTokenOfOwnerByIndex] = useState(0);
  const [account, setAccount] = useState("");

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    ZUKAN_SITE: "",
    ZUKAN_SITE_ID: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checkHolder = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .getBalanceOf(blockchain.account)
        .call()
        .then((receipt) => {
          setHold(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkTokenID = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .getTokenOfOwnerByIndex(blockchain.account, 0)
        .call()
        .then((receipt) => {
          setZukanID(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkAccount = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .getTokenOfOwnerByIndex(blockchain.account, 0)
        .call()
        .then((receipt) => {
          const zukanID = receipt;
          setZukanID(zukanID);
          console.log('ZukanID:', zukanID);
          blockchain.smartContract.methods
            .getAccount(zukanID)
            .call()
            .then((accountReceipt) => {
              setAccount(accountReceipt);
              dispatch(fetchData(blockchain.account));
            });
        });
    }
  };

  const checkTotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .totalSupply()
        .call()
        .then((receipt) => {
          setTotalSupply(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkSaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .saleStart()
        .call()
        .then((receipt) => {
          setSaleStart(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkClaimed = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .claimed(blockchain.account)
        .call()
        .then((receipt) => {
          setClaimed(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };
  const checkTokenOfOwnerByIndex = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .tokenOfOwnerByIndex(blockchain.account, 0)
        .call()
        .then((receipt) => {
          setTokenOfOwnerByIndex(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const getAL = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/al.json");
      let validNuns = whitelist.map((addr) => addr.slice(-64));
      let adresses = whitelist.map((addr) => addr.slice(0, -64));
      let index = adresses.indexOf(address);
      let validNun = validNuns[index];
      let mintNum = 0;
      if (validNun !== undefined) {
        mintNum = parseInt(validNun, 16);
      }
      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address + validNun);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      //console.log("freeMint:proof/" + proof);
      console.log("freeMint:root/" + root);
      console.log("freeMint:count/" + mintNum);
      setProof(proof);
      setMintNum(mintNum);
      dispatch(fetchData(blockchain.account));
    }
  };

  const claimNFTs = async () => {
    let method = await blockchain.smartContract.methods.mint(FreemintNum, FreemintNum, proof);
    setFeedback(``);
    setClaimingNft(true);
    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      });
      console.log(receipt);
      setFeedback(
        ``
      );
      setClaimingNft(false);
      checkClaimed();
      checkTotalSupply();
      checkTokenOfOwnerByIndex();
      checkAccount();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedback("もう一度試してみてください");
      setClaimingNft(false);
    }
  };

  const TransferNFTs = async () => {
    let method = await blockchain.smartContract.methods.transferFrom(blockchain.account, account, tokenOfOwnerByIndex);
    setFeedback(``);
    setTransferNft(true);
    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      });
      console.log(receipt);
      setFeedback(
        `Transfer完了!図鑑サイトに移管します!`
      );
      setTransferNft(false);
      checkTokenOfOwnerByIndex();
      dispatch(fetchData(blockchain.account));
      await new Promise(resolve => setTimeout(resolve, 3000));
      window.location.href = CONFIG.ZUKAN_SITE_ID + ZukanID;
    } catch (err) {
      console.log(err);
      setFeedback("もう一度試してみてください");
      setTransferNft(false);
    }
  };

  useEffect(() => {
    getData();
    getConfig();
    checkHolder();
    checkTotalSupply();
    checkClaimed();
    checkSaleStart();
    getAL();
    checkTokenOfOwnerByIndex();
    checkTokenID();
    checkAccount();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkHolder();
    checkTotalSupply();
    checkClaimed();
    checkSaleStart();
    getAL();
    checkTokenOfOwnerByIndex();
    checkTokenID();
    checkAccount();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ background: "rgba(0, 0, 0, 0.1)" }}>
      <s.Container
        flex={1}
        ai={"center"}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/1.jpg" : null}
        style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat"}}
      >

        <s.Container
          flex={1}
          ai={"center"}
          style={{ background: "rgba(0, 0, 0, 0.6)"}}
        >
          <s.TextDescription style={{ position: 'fixed', width: '100%', fontSize: 26, textAlign: "left", backgroundColor: "rgba(0, 0, 0)" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <s.StyledLink style={{ color: "#fff", margin: "8px 0px 0px 24px" }} href="">
                <img style={{ width: "82px", paddingBottom: "8px", marginTop: "0px", marginRight: "12px" }} src="/config/images/logo.png" alt="LOGO"></img>
              </s.StyledLink>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: "22px" }}>
                <a href="https://twitter.com/shinsekai_dao" target="_blank" rel="noopener" style={{ marginRight: "12px" }}>
                  <img style={{ width: "22px", marginTop: "4px" }} src="/config/images/x.png" alt="X Icon"></img>
                </a>
                <a href={CONFIG.MARKETPLACE_LINK} rel="noopener">
                  <img style={{ width: "26px", marginTop: "4px" }} src="/config/images/os.png" alt="OpenSea Icon"></img>
                </a>
              </div>
            </div>
          </s.TextDescription>

          <s.Container flex={1} ai={"center"}>
            <s.Container ai={"center"} jc={"center"} fd={"row"} style={{ flexWrap: "wrap", marginTop: "120px" }}>
              {/*<img className="logo" style={{ maxWidth: "60%", width: "280px", marginTop: "120px" }} src="/config/images/logo.png" alt="Logo" />*/}
            </s.Container>
            <s.ResponsiveWrapper flex={1} style={{ padding: "0px 20px 10px 20px", maxWidth: "480px" }}>
              <s.Container flex={1} ai={"center"} style={{ padding: "0px 0px", borderRadius: 12, backgroundColor: "rgba(0, 0, 0, 0)" }}>
                <s.Container flex={2} ai={"center"} style={{ backgroundColor: "rgba(0, 0, 0, 0)", padding: 5, borderRadius: 24, border: "none", boxShadow: "0px 5px 11px 2px rgba(0,0,0,0)" }}>
                  <s.TextTitle style={{ textAlign: "center", fontSize: 36, color: "rgba(255,255,255,1)", margin: "48px 0px 16px"}}>
                    HYAKKI YAKO
                  </s.TextTitle>
                  <s.TextTitle style={{ textAlign: "center", fontSize: 32, color: "rgba(255,255,255,1)", }}>
                    {totalSupply} / {CONFIG.MAX_SUPPLY}
                  </s.TextTitle>

                  {blockchain.account === "" || blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.SpacerSmall />
                      <s.StyledButton
                       onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                      >
                        CONNECT
                      </s.StyledButton>
                      <s.SpacerSmall />
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <div style={{color:"#fff"}}>{blockchain.errorMsg}</div>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.Container ai={"center"} jc={"center"}>
                        <s.SpacerLarge />
                        <div style={{ color: "#fff" }}>{truncate(blockchain.account, 4)}{truncate(blockchain.account.slice(-4), 4)}</div>
                      </s.Container>
                    </>
                  )}

                  {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                      <s.TextDescription>Sold out!!</s.TextDescription>
                      <s.StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                        <img style={{ width: "32px", marginTop: "4px" }} src="/config/images/os.png" alt="OpenSea Icon"></img>
                      </s.StyledLink>
                    </>
                  ) : (
                    <>
                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          {blockchain.errorMsg !== "" ? (
                            <></>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          {/* ここから  -----------------------------------------------------------------*/}
                          <div style={{ color: "#fff", margin: "24px 0px 12px", fontSize: "18px" }}>- 百鬼夜行 -</div>
                          {proof != "" ? (
                            <>
                              {saleStart == true ? (
                                <>
                                  {claimed > FreemintNum - 1 ? (
                                    <>
                                      <s.StyledButtonClamed
                                        disabled={1}//claimingAlNftがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        mint済みです
                                      </s.StyledButtonClamed>

                                      {tokenOfOwnerByIndex >= 1 ? (
                                        <>
                                          {hold >= 1 ? (
                                            <>
                                              <s.TextDescription style={{ marginTop: "12px" }}>ミントした百鬼夜行NFTを百鬼図鑑NFTに収集する</s.TextDescription>
                                              <s.StyledButton
                                                disabled={TransferNft ? 1 : 0}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  TransferNFTs();
                                                  getData();
                                                }}
                                              >
                                                {TransferNft ? "Transferしています..." : "百鬼図鑑にTransfer"}
                                              </s.StyledButton>
                                              <s.TextDescription style={{ marginTop: "12px" }}>※トランスファーするとOpenSeaから百鬼図鑑NFT(ERC6551)に送付され、ミントサイトから百鬼図鑑の専用サイトへ遷移します</s.TextDescription>

                                              {/*<s.TextDescription>お持ちの百鬼図鑑(ID:{ZukanID})</s.TextDescription>*/}
                                              {/*<s.TextDescription>図鑑に紐ずくアドレス:{truncate(account, 4)}{truncate(account.slice(-4), 4)}</s.TextDescription>*/}
                                            </>
                                          ) : (
                                            <div>
                                              <s.SpacerSmall />
                                              <s.TextDescription>百鬼図鑑NFT(ERC-6551)があれば百鬼夜行NFTをコレクトして楽しむことができます</s.TextDescription>
                                              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                                <a href={CONFIG.ZUKAN_SITE} target="_blank" rel="noopener">
                                                  <s.StyledButton>
                                                    百鬼図鑑ミントサイトへ
                                                  </s.StyledButton>
                                                </a>
                                              </s.Container>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <s.SpacerMedium></s.SpacerMedium>
                                          <s.StyledButtonClamed>
                                          百鬼図鑑にトランスファー済
                                          </s.StyledButtonClamed>
                                          <s.TextDescription>以下のアドレスにトランスファーされています</s.TextDescription>
                                          <s.TextDescription>{account}</s.TextDescription>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <s.StyledButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTs();
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Mintしています..." : "Mint"}
                                      </s.StyledButton>
                                      <s.SpacerXSmall />
                                      <s.TextDescription>あなたのmint数：{FreemintNum}mint</s.TextDescription>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.StyledButtonClamed>
                                    セール開始をお待ちください
                                  </s.StyledButtonClamed>
                                  <s.SpacerXSmall />
                                  <s.TextDescription>あなたのmint数：{FreemintNum}</s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <s.StyledButtonClamed disabled={1} onClick={(e) => { e.preventDefault(); }}>
                                ALを保有していません
                              </s.StyledButtonClamed>
                            </>
                          )}
                        </>
                      )}
                      <s.TextDescription>{feedback}</s.TextDescription>
                    </>
                  )}
                  <s.SpacerXSmall />
                </s.Container>
              </s.Container>
            </s.ResponsiveWrapper>
          </s.Container>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}
export default App;